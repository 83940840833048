
import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from "vuex-class";

@Component
export default class Aside extends Vue {
    @State("user") user: any;
    @Prop({
        default: () => {
            return []
        },
    })
    menu!: any[]
    get activePath() {
        return this.$route.meta?.prevPath || this.$route.path
    }

    get userInfo() {
        return this.user.userInfo;
    }
}
