import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/manager',
  name: 'manager',
  meta: { title: '供应商' },
  redirect: '/manager/lists',
  super: true,
  component: Main,
  children: [
    {
      path: '/manager/lists',
      name: 'lists',
      meta: {
        title: '用户管理',
        parentPath: '/manager',
        icon: 'icon_ziti_store',
        permission: ['view']
      },
      component: Blank,
      redirect: '/manager/lists',
      children: [
        {
          path: '/manager/lists',
          name: 'manager_lists',
          meta: {
            title: '用户列表',
            parentPath: '/manager',
            permission: ['view']
          },
          component: () => import('@/views/manager/manager/lists.vue')
        },
        {
          path: '/manager/lists_detail',
          name: 'lists_detail',
          meta: {
            hidden: true,
            title: '用户信息',
            parentPath: '/manager',
            prevPath: '/manager/lists',
            permission: ['view']
          },
          component: () => import('@/views/manager/manager/lists_edit.vue')
        },
        {
          path: '/manager/modify_account',
          name: 'modify_account',
          meta: {
            hidden: true,
            title: '修改超级管理员账号',
            parentPath: '/manager',
            prevPath: '/manager/lists',
            permission: ['view']
          },
          component: () => import('@/views/manager/manager/modify_account.vue')
        },
        {
          path: '/manager/package_record',
          name: 'package_record',
          meta: {
            hidden: true,
            title: '套餐记录',
            parentPath: '/manager',
            prevPath: '/manager/lists',
            permission: ['view']
          },
          component: () => import('@/views/manager/manager/package_record.vue')
        }
      ]
    },
    {
      path: '/manager/set_meal',
      name: 'set_meal',
      meta: {
        title: '套餐管理',
        parentPath: '/manager',
        icon: 'icon_order_guanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/manager/set_meal',
      children: [
        {
          path: '/manager/set_meal',
          name: 'set_meal',
          meta: {
            title: '套餐列表',
            parentPath: '/manager',
            permission: ['view']
          },
          component: () => import('@/views/manager/set_meal/lists.vue')
        },
        {
          path: '/manager/set_meal_edit',
          name: 'set_meal_edit',
          meta: {
            hidden: true,
            title: '商城套餐',
            parentPath: '/manager',
            prevPath: '/manager/set_meal',
            permission: ['view']
          },
          component: () => import('@/views/manager/set_meal/edit.vue')
        }
      ]
    }
  ]
}]

export default routes
