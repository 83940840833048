import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
    {
        path: '/channel',
        name: 'channel',
        meta: { title: '渠道' },
        redirect: '/channel/mp_wechat',
        super: true,
        component: Main,
        children: [{
            path: '/channel/mp_wechat',
            name: 'mp_wechat_index',
            meta: {
                title: '渠道设置',
                icon: "icon_qudao_weixin",
                parentPath: '/channel',
                permission: ['view']
            },
            component: () => import('@/views/channel/mp_wechat/index.vue')
        },]
    }
]

export default routes
